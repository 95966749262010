import React, { useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Layout from "./layouts/layout";
import Dashboard from "./pages/Dashboard";
import Login from "./pages/Login";
import Registration from "./pages/registration";
import PasswordResetForm from "./pages/ResetPassword";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AuthProvider } from "./utils/AuthContext";

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false); // Track login stat

  return (
    <AuthProvider> 
    <div className="App">
      {/* ToastContainer for toast notifications */}
      <ToastContainer
        position="top-right" // Position of the toast
        autoClose={5000}     // Auto close duration in ms
        hideProgressBar={false} // Show progress bar
        newestOnTop={false}  // Show newest toast on top
        closeOnClick         // Close on click
        rtl={false}          // Right-to-left support
        pauseOnFocusLoss     // Pause toast when the browser loses focus
        draggable            // Allow drag to dismiss
        pauseOnHover         // Pause on hover
      />

      <Router>
        <Routes>
          {console.log("isAuthenticated",isAuthenticated)}
          <Route
            path="/"
            element={
              !isAuthenticated ? (
                <Login onLogin={() => setIsAuthenticated(true)} />
              ) : (
                <Navigate to="/dashboard" replace />
              )
            }
          />

          {/* Route for the Dashboard Page */}
          <Route
            path="/dashboard"
            element={
              isAuthenticated ? (
                <Layout>
                  <Dashboard />
                </Layout>
              ) : (
                <Navigate to="/" replace />
              )
            }
          />
          <Route path="/register" element={<Registration />} />
          <Route path="/password-reset" element={<PasswordResetForm />} />
          {/* Redirect any other routes to the login page */}
          <Route path="*" element={<Navigate to="/" replace />} />
          <Route path="/login" element={<Login />} />
        </Routes>
      </Router>
    </div>
    </AuthProvider>
  );
};

export default App;