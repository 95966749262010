import React, { useState, useEffect } from "react";
import {
  AppBar,
  Toolbar,
  Box,
  Button,
  Typography,
  TextField,
  Paper,
  Checkbox,
  FormControlLabel,
  Snackbar,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useAuth } from "../utils/AuthContext";
import { signInWithEmailPassword } from "../utils/identityManager";

const Login = ({ onLogin }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const { setUser } = useAuth();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  // Check for token in localStorage on component mount
  useEffect(() => {
    const token = localStorage.getItem("authToken");
    const storedUsername = localStorage.getItem("username");

    if (token) {
      setIsLoggedIn(true);
      setToastMessage(`Welcome back, ${storedUsername || "User"}`);
      setShowToast(true);
      onLogin(); // Trigger the login callback
    }
  }, [onLogin]);

  const handleLogin = async () => {
    try {
      const user = await signInWithEmailPassword(username, password);
  
      if (user && user.accessToken) {
        setUser(user); // This updates the context and persists to localStorage
        setToastMessage(`Welcome, ${user.email}`);
        setShowToast(true);
        onLogin(); // Trigger the login callback
      }
    } catch (error) {
      setToastMessage("Invalid email or password. Please try again.");
      setShowToast(true);
    }
  };
  

  const handleLogout = () => {
    // Clear token and user info from localStorage
    localStorage.removeItem("authToken");
    localStorage.removeItem("username");
    setIsLoggedIn(false);
    setToastMessage("Logged out successfully.");
    setShowToast(true);
  };

  return (
    <Box
      sx={{
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        backgroundImage: "url('/BackgroundSmall.png')",
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        overflow: "hidden",
      }}
    >
      {/* AppBar Section */}
      <AppBar
        position="fixed"
        sx={{
          backgroundColor: "white",
          boxShadow: "none",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
      >
        <Toolbar
          sx={{
            justifyContent: "space-between",
            padding: "0 20px",
            height: "60px",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <img
              src="/logo.png"
              alt="Logo"
              style={{
                height: "40px",
                marginRight: "20px",
                marginLeft: "20px",
              }}
            />
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", gap: "30px" }}>
            {isLoggedIn ? (
              <Button
                variant="text"
                color="inherit"
                onClick={handleLogout}
                sx={{ fontSize: "14px", textTransform: "none" }}
              >
                Logout
              </Button>
            ) : (
              <Typography fontSize="16px" sx={{ color: "#7A7C82", fontWeight: "bold" }}>
                Need Help?
              </Typography>
            )}
          </Box>
        </Toolbar>
      </AppBar>

      {/* Main Content Section */}
      {!isLoggedIn ? (
        <Box
          sx={{
            flexGrow: 1,
            display: "flex",
            flexDirection: isMobile ? "column" : "row",
            justifyContent: "center",
            alignItems: "center",
            gap: 3,
            paddingTop: "80px",
          }}
        >
          {/* Image Section */}
          <Box
            sx={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              padding: "10px",
            }}
          >
            <Typography sx={{ mb: 2, textAlign: "center", fontSize: "28px" }}>
              Track your equipment in real time
            </Typography>
            <img
              src="/login_logo.png"
              alt="Equipment Tracking"
              style={{
                width: "80%",
                maxWidth: "300px",
                height: "auto",
              }}
            />
          </Box>

          {/* Form Section */}
          <Box sx={{ flex: 1, display: "flex", justifyContent: "center", padding: "10px" }}>
            <Paper
              sx={{
                width: "100%",
                maxWidth: "350px",
                padding: "15px",
                boxShadow: "0px 0px 16px 0px #9BA3B440",
              }}
            >
              <Typography variant="h6" sx={{ mb: 1, textAlign: "left", fontSize: "18px" }}>
                Welcome to Nayaati
              </Typography>
              <Typography variant="body2" sx={{ mb: 1, textAlign: "left", fontSize: "14px" }}>
                Enter your email and password to access your account.
              </Typography>

              {/* Username Field */}
              <Typography sx={{ textAlign: "left", mb: 0.5, fontSize: "14px" }}>Username</Typography>
              <TextField
                variant="outlined"
                fullWidth
                sx={{ mb: 1, "& .MuiOutlinedInput-root": { height: 32 } }}
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />

              {/* Password Field */}
              <Typography sx={{ textAlign: "left", mb: 0.5, fontSize: "14px" }}>Password</Typography>
              <TextField
                type="password"
                variant="outlined"
                fullWidth
                sx={{ mb: 1.5, "& .MuiOutlinedInput-root": { height: 32 } }}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />

              <FormControlLabel control={<Checkbox />} label="Remember Me" sx={{ mb: 1.5 }} />

              {/* Login Button */}
              <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={handleLogin}
                sx={{
                  mb: 1.5,
                  padding: "4px",
                  minHeight: "32px",
                  fontSize: "14px",
                  backgroundColor: "#3644BE",
                  "&:hover": {
                    backgroundColor: "#1565c0",
                  },
                }}
              >
                Login
              </Button>
            </Paper>
          </Box>
        </Box>
      ) : (
        <Box sx={{ flexGrow: 1, textAlign: "center", padding: "20px" }}>
          <Typography variant="h4">Welcome Back!</Typography>
        </Box>
      )}

      {/* Snackbar for Messages */}
      <Snackbar
        open={showToast}
        onClose={() => setShowToast(false)}
        message={toastMessage}
        autoHideDuration={3000}
      />
    </Box>
  );
};

export default Login;