// Dashboard.js
import React, { useState } from "react";
import {
  Paper,
  Select,
  MenuItem,
  Typography,
} from "@mui/material";
import { Alert, IOT, Equipment, Timer } from "../Icons/Icons";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import L from "leaflet";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import "leaflet/dist/leaflet.css";
import { SeverityInfoChip } from "../components/common/SeverityInfoChip";
import { useAuth } from "../utils/AuthContext";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const Dashboard = () => {
  const [selectedDay, setSelectedDay] = useState("Today");
  const [selectedEquipmentCount, setSelectedEquipmentCount] = useState(10);
  const [selectedLocation, setSelectedLocation] = useState("California");
  const [selectedEquipment, setSelectedEquipment] = useState("Cranes");
   const { authState } = useAuth();

  const handleLocationChange = (event) => {
    setSelectedLocation(event.target.value);
  };

  const handleEquipmentChange = (event) => {
    setSelectedEquipment(event.target.value);
  };

  const handleDayChange = (event) => {
    setSelectedDay(event.target.value);
  };

  const handleEquipmentCountChange = (event) => {
    setSelectedEquipmentCount(event.target.value);
  };

  const boxes = [
    { heading: "Active vs Idle Equipment", value: "105/458", icon: Alert },
    { heading: "Active vs Idle Equipment", value: "105", icon: IOT },
    { heading: "Active vs Idle Equipment", value: "105/458", icon: Equipment },
    { heading: "Active vs Idle Equipment", value: "105/458", icon: Timer },
  ];

  const mapData = [
    { id: 1, name: "Bulldozer", position: [51.505, -0.09], color: "green" },
    { id: 2, name: "Excavator", position: [51.515, -0.1], color: "pink" },
    { id: 3, name: "Compiler", position: [51.525, -0.12], color: "purple" },
    { id: 4, name: "Cranes", position: [51.535, -0.13], color: "blue" },
  ];

  const tableData = [
    {
      id: 1,
      equipment_name: "ONC Excavator",
      iot_device_name: "Power Line",
      alarm: "Power Failure Alarm",
      date_time: "23/12/2024, 11:09 am",
      severity: "Critical",
      status: "Resolved",
    },
    {
      id: 2,
      equipment_name: "Hydraulic Press",
      iot_device_name: "Pressure Valve",
      alarm: "Pressure Drop Alarm",
      date_time: "23/12/2024, 09:30 am",
      severity: "High",
      status: "Unresolved",
    },
    {
      id: 3,
      equipment_name: "CNC Machine",
      iot_device_name: "Temperature Sensor",
      alarm: "Overheating Alarm",
      date_time: "22/12/2024, 04:15 pm",
      severity: "Critical",
      status: "Resolved",
    },
    {
      id: 4,
      equipment_name: "Welding Robot",
      iot_device_name: "Motion Sensor",
      alarm: "Motion Detection Alarm",
      date_time: "21/12/2024, 12:45 pm",
      severity: "Medium",
      status: "Unresolved",
    },
    {
      id: 5,
      equipment_name: "Automated Conveyor",
      iot_device_name: "Motor",
      alarm: "Motor Failure Alarm",
      date_time: "20/12/2024, 10:00 am",
      severity: "High",
      status: "Resolved",
    },
    {
      id: 6,
      equipment_name: "Cooling Unit",
      iot_device_name: "Temperature Sensor",
      alarm: "Cooling System Malfunction",
      date_time: "19/12/2024, 02:30 pm",
      severity: "Low",
      status: "Unresolved",
    },
    {
      id: 7,
      equipment_name: "Robotic Arm",
      iot_device_name: "Pressure Sensor",
      alarm: "Pressure Threshold Alarm",
      date_time: "18/12/2024, 11:00 am",
      severity: "Critical",
      status: "Resolved",
    },
    {
      id: 8,
      equipment_name: "Grinder Machine",
      iot_device_name: "Vibration Sensor",
      alarm: "Excessive Vibration Alarm",
      date_time: "17/12/2024, 03:15 pm",
      severity: "Medium",
      status: "Unresolved",
    },
  ];

  const deviceSensorTableData = [
    {
      id: 1,
      iot_device_name: "Temperature Sensor A1",
      location: 30,
      battery_level: "85%",
      temperature: "22°C",
      pressure: "Normal",
      last_active: "5 minutes ago", // Last active 5 minutes ago
      status: "Active",
    },
    {
      id: 2,
      iot_device_name: "Humidity Sensor B2",
      location: 45,
      battery_level: "65%",
      temperature: "18°C",
      pressure: "Normal",
      last_active: "1 day ago", // Last active 1 day ago
      status: "Inactive",
    },
    {
      id: 3,
      iot_device_name: "Motion Detector C3",
      location: 60,
      battery_level: "100%",
      temperature: "21°C",
      pressure: "Normal",
      last_active: "10 minutes ago", // Last active 10 minutes ago
      status: "Active",
    },
    {
      id: 4,
      iot_device_name: "Light Sensor D4",
      location: 25,
      battery_level: "45%",
      temperature: "23°C",
      pressure: "Low",
      last_active: "2 hours ago", // Last active 2 hours ago
      status: "Offline",
    },
    {
      id: 5,
      iot_device_name: "Pressure Sensor E5",
      location: 50,
      battery_level: "90%",
      temperature: "24°C",
      pressure: "High",
      last_active: "3 hours ago", // Last active 3 hours ago
      status: "Active",
    },
    {
      id: 6,
      iot_device_name: "Gas Sensor F6",
      location: 40,
      battery_level: "70%",
      temperature: "20°C",
      pressure: "Normal",
      last_active: "1 day ago", // Last active 1 day ago
      status: "Inactive",
    },
    {
      id: 7,
      iot_device_name: "Air Quality Monitor G7",
      location: 70,
      battery_level: "50%",
      temperature: "19°C",
      pressure: "Normal",
      last_active: "3 days ago", // Last active 3 days ago
      status: "Active",
    },
    {
      id: 8,
      iot_device_name: "Water Leak Sensor H8",
      location: 55,
      battery_level: "30%",
      temperature: "25°C",
      pressure: "Normal",
      last_active: "6 hours ago", // Last active 6 hours ago
      status: "Offline",
    },
  ];

  const graphData = {
    labels: [
      "12:00",
      "13:00",
      "14:00",
      "15:00",
      "16:00",
      "17:00",
      "18:00",
      "19:00",
    ],
    datasets: [
      {
        label: "Cranes",
        data: [1, 3, 5, 2, 6, 4, 5, 7],
        borderColor: "green",
        backgroundColor: "rgba(0, 255, 0, 0.2)",
        fill: false,
      },
      {
        label: "Excavators",
        data: [0, 2, 3, 4, 5, 4, 3, 5],
        borderColor: "pink",
        backgroundColor: "rgba(255, 0, 255, 0.2)",
        fill: false,
      },
      {
        label: "Bulldozers",
        data: [2, 4, 5, 3, 2, 1, 2, 3],
        borderColor: "purple",
        backgroundColor: "rgba(128, 0, 128, 0.2)",
        fill: false,
      },
      {
        label: "Compactors",
        data: [1, 4, 3, 6, 7, 5, 6, 8],
        borderColor: "blue",
        backgroundColor: "rgba(0, 0, 255, 0.2)",
        fill: false,
      },
      {
        label: "Dump Trucks",
        data: [5, 6, 5, 7, 6, 8, 9, 9],
        borderColor: "orange",
        backgroundColor: "rgba(255, 165, 0, 0.2)",
        fill: false,
      },
      {
        label: "Rollers",
        data: [3, 2, 4, 5, 4, 6, 6, 7],
        borderColor: "red",
        backgroundColor: "rgba(255, 0, 0, 0.2)",
        fill: false,
      },
      {
        label: "Excavators Mini",
        data: [6, 7, 6, 8, 7, 8, 7, 8],
        borderColor: "yellow",
        backgroundColor: "rgba(255, 255, 0, 0.2)",
        fill: false,
      },
      {
        label: "Grinders",
        data: [7, 8, 6, 7, 8, 9, 8, 10],
        borderColor: "brown",
        backgroundColor: "rgba(139, 69, 19, 0.2)",
        fill: false,
      },
    ],
  };

  return (
    <div className="dashboard">
      <div className="header">
        {/* Welcome Message */}
        <div className="welcome-message">Welcome, {authState?.user?.displayName || "User"}</div>

        {/* Dropdown for days */}
        <div className="day-dropdown">
          {/* <select value={selectedDay} onChange={handleDayChange}>
            <option value="Today">Today</option>
            <option value="Tomorrow">Tomorrow</option>
            <option value="Yesterday">Yesterday</option>
          </select> */}
          <Select
            value={selectedDay}
            onChange={handleDayChange}
            displayEmpty
            style={{ width: 150 }}
          >
            <MenuItem value={"Today"}>Today</MenuItem>
            <MenuItem value={"Tomorrow"}>Tomorrow</MenuItem>
            <MenuItem value={"Yesterday"}>Yesterday</MenuItem>
          </Select>
        </div>
      </div>

      {/* Box Section */}
      <div className="box-container">
        {boxes.map((box, index) => (
          <div className="box" key={index}>
            <div className="box-text-fields">
              <div className="box-heading">{box.heading}</div>
              <div className="box-value">
                {/* Check if there's a "/" in the value and split accordingly */}
                {box.value.includes("/") ? (
                  <>
                    <span className="numerator">{box.value.split("/")[0]}</span>
                    <span className="slash">/</span>
                    <span className="denominator">
                      {box.value.split("/")[1]}
                    </span>
                  </>
                ) : (
                  <span className="numerator">{box.value}</span> // If no fraction, treat the value as numerator
                )}
              </div>
            </div>
            <div className="box-icon">
              <box.icon />
            </div>
          </div>
        ))}
      </div>

      {/*Table Paper Component */}
      <Paper elevation={3} className="paper-container">
        <div className="paper-heading">
          <h3>Equipment Alerts and Alarms Log</h3>
          <div className="equipment-dropdown">
            <Select
              value={selectedEquipmentCount}
              onChange={handleEquipmentCountChange}
              displayEmpty
              inputProps={{ "aria-label": "Equipment Count" }}
              style={{ width: 180 }} // Increased width to fit the "10 Equipments" text
            >
              <MenuItem value={10}>10 Equipments</MenuItem>
              <MenuItem value={20}>20 Equipments</MenuItem>
              <MenuItem value={30}>30 Equipments</MenuItem>
              <MenuItem value={40}>40 Equipments</MenuItem>
            </Select>
          </div>
        </div>

        <div className="table-container">
          <table className="data-table">
            <thead>
              <tr>
                <th>Equipment Name</th>
                <th>IoT Device Name</th>
                <th>Alarm</th>
                <th>Date & Time</th>
                <th>Severity</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {tableData.map((row, index) => (
                <tr
                  key={row.id}
                  className={index % 2 === 0 ? "even-row" : "odd-row"}
                >
                  <td>
                    <a href={row.link}>{row.equipment_name}</a>
                  </td>
                  <td>{row.iot_device_name}</td>
                  <td>{row.alarm}</td>
                  <td>{row.date_time}</td>
                  <td>
                    {
                      <SeverityInfoChip color="#DF0000">
                        <Typography fontSize="x-medium" color={"#DF0000"}>
                          {row.severity}
                        </Typography>
                      </SeverityInfoChip>
                    }
                  </td>
                  <td>{row.status}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </Paper>

      {/* Map Component */}
      <div className="papers-container">
        {/* Paper for the map */}
        <Paper elevation={3} className="map-paper">
          <div className="map-paper-heading">
            <h3>Location Overview</h3>
            <Select
              value={selectedLocation}
              onChange={handleLocationChange}
              displayEmpty
              inputProps={{ "aria-label": "Location" }}
              style={{ width: 180 }}
            >
              <MenuItem value={"California"}>California</MenuItem>
              <MenuItem value={"Ohio"}>Ohio</MenuItem>
              <MenuItem value={"San Fransisco"}>San Fransisco</MenuItem>
            </Select>
          </div>

          <div className="map-container">
            <MapContainer
              center={[51.505, -0.09]}
              zoom={13}
              style={{ height: "350px", width: "100%" }}
            >
              <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              />
              {mapData.map((marker) => (
                <Marker
                  key={marker.id}
                  position={marker.position}
                  icon={
                    new L.Icon({
                      iconUrl: `https://upload.wikimedia.org/wikipedia/commons/e/ec/Blue_marker.svg`, // Use a blue marker as a placeholder
                      iconSize: [25, 41],
                      iconAnchor: [12, 41],
                      popupAnchor: [1, -34],
                    })
                  }
                >
                  <Popup>{marker.name}</Popup>
                </Marker>
              ))}
            </MapContainer>
          </div>

          <div className="legends">
            <h4>Legends</h4>
            <div className="legend-items">
              <div className="legend-item">
                <span
                  className="legend-color"
                  style={{ backgroundColor: "green" }}
                ></span>
                <span>Bulldozer</span>
              </div>
              <div className="legend-item">
                <span
                  className="legend-color"
                  style={{ backgroundColor: "pink" }}
                ></span>
                <span>Excavator</span>
              </div>
              <div className="legend-item">
                <span
                  className="legend-color"
                  style={{ backgroundColor: "purple" }}
                ></span>
                <span>Compiler</span>
              </div>
              <div className="legend-item">
                <span
                  className="legend-color"
                  style={{ backgroundColor: "blue" }}
                ></span>
                <span>Cranes</span>
              </div>
            </div>
          </div>
        </Paper>

        {/* Blank Paper for now */}
        <Paper
          elevation={3}
          sx={{
            padding: "20px",
            width: "100%",
          }}
          className="blank-paper"
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <h3>Utilization Trends</h3>
            <Select
              value={selectedEquipment}
              onChange={handleEquipmentChange}
              displayEmpty
              sx={{ width: "200px" }}
            >
              <MenuItem value="Cranes">Cranes</MenuItem>
              <MenuItem value="Excavators">Excavators</MenuItem>
              <MenuItem value="Bulldozers">Bulldozers</MenuItem>
              <MenuItem value="Compactors">Compactors</MenuItem>
              <MenuItem value="Dump Trucks">Dump Trucks</MenuItem>
              <MenuItem value="Rollers">Rollers</MenuItem>
              <MenuItem value="Excavators Mini">Excavators Mini</MenuItem>
              <MenuItem value="Grinders">Grinders</MenuItem>
            </Select>
          </div>
          <div style={{ height: "350px", marginTop: "20px" }}>
            <Line
              data={graphData}
              options={{
                responsive: true,
                scales: {
                  x: {
                    title: {
                      display: true,
                      text: "Timeframe (Hrs)",
                    },
                  },
                  y: {
                    title: {
                      display: true,
                      text: "Utilization Rate (%)",
                    },
                    suggestedMin: 0,
                    suggestedMax: 10,
                  },
                },
              }}
            />
          </div>
        </Paper>
      </div>

      {/* DEVICE SNESOR TABLE*/}
      <Paper elevation={3} className="ds-paper-container">
        <div className="ds-paper-heading">
          <h3>Equipment Alerts and Alarms Log</h3>
          <div className="ds-equipment-dropdown">
            <Select
              value={selectedEquipmentCount}
              onChange={handleEquipmentCountChange}
              displayEmpty
              inputProps={{ "aria-label": "Equipment Count" }}
              style={{ width: 180 }} // Increased width to fit the "10 Equipments" text
            >
              <MenuItem value={10}>10 Equipments</MenuItem>
              <MenuItem value={20}>20 Equipments</MenuItem>
              <MenuItem value={30}>30 Equipments</MenuItem>
              <MenuItem value={40}>40 Equipments</MenuItem>
            </Select>
          </div>
        </div>

        <div className="ds-table-container">
          <table className="ds-data-table">
            <thead>
              <tr>
                <th>IoT Device Name</th>
                <th>Location</th>
                <th>Battery Level (%)</th>
                <th>Temperature (Degrees)</th>
                <th>Pressure (PSI)</th>
                <th>Last Active</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {deviceSensorTableData.map((row, index) => (
                <tr
                  key={row.id}
                  className={index % 2 === 0 ? "ds-even-row" : "ds-odd-row"}
                >
                  <td>
                    <a href={row.link}>{row.iot_device_name}</a>
                  </td>
                  <td>{row.location}</td>
                  <td>{row.battery_level}</td>
                  <td>{row.temperature}</td>
                  <td>{row.pressure}</td>
                  <td>{row.last_active}</td>
                  <td>
                    {
                      <SeverityInfoChip color="#DF0000">
                        <Typography fontSize="x-medium" color={"#DF0000"}>
                          {row.status}
                        </Typography>
                      </SeverityInfoChip>
                    }
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </Paper>

      <hr />
      <footer class="footer">
        <p>&copy; 2024 Copyright, All Rights Reserved</p>
      </footer>
    </div>
  );
};

export default Dashboard;
