import React, { useState } from "react";
import { Box, Button, Typography, TextField } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

const BasicDetailsForm = ({ handleNext, onDataChange }) => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
  });

  const [errors, setErrors] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "phone") {
      // Restrict phone input to 10 digits only
      if (value.length > 10 || !/^\d*$/.test(value)) return;
    }

    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));

    onDataChange({ [name]: value });

    validateField(name, value);
  };

  const validateField = (name, value) => {
    let error = "";

    switch (name) {
      case "firstName":
      case "lastName":
        if (!value.trim()) {
          error = `${name === "firstName" ? "First" : "Last"} Name is required`;
        } else if (!/^[a-zA-Z]+$/.test(value)) {
          error = "Only alphabets are allowed";
        }
        break;

      case "email":
        if (!value.trim()) {
          error = "Email is required";
        } else if (
          !/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/.test(value)
        ) {
          error = "Invalid email format";
        }
        break;

      case "phone":
        if (!value.trim()) {
          error = "Phone number is required";
        } else if (value.length !== 10) {
          error = "Phone number must be exactly 10 digits";
        }
        break;

      default:
        break;
    }

    setErrors((prev) => ({
      ...prev,
      [name]: error,
    }));
  };

  const isFormValid = () => {
    return (
      formData.firstName &&
      !errors.firstName &&
      formData.lastName &&
      !errors.lastName &&
      formData.email &&
      !errors.email &&
      formData.phone &&
      !errors.phone
    );
  };

  return (
    <Box
      sx={{
        mt: 0,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
        maxWidth: "600px",
        mx: "auto",
      }}
    >
      <Typography
        variant="h6"
        sx={{ mb: 0, fontSize: "18px", fontWeight: "bold", mt: 0 }}
      >
        Tell Us About You
      </Typography>
      <Typography
        variant="body2"
        sx={{ mb: 1, fontSize: "14px", color: "#6B7280" }}
      >
        Provide your basic details to get started with Nayaati's vendor platform.
      </Typography>

      <Box
        sx={{
          display: "flex",
          gap: 2,
          flexDirection: { xs: "column", sm: "row" },
          // mb: 2,
        }}
      >
        <TextField
          label="First Name"
          name="firstName"
          value={formData.firstName}
          onChange={handleChange}
          variant="outlined"
          fullWidth
          error={!!errors.firstName}
          helperText={errors.firstName}
          InputProps={{
            sx: { height: "36px" },
          }}
          InputLabelProps={{
            sx: { fontSize: "12px", paddingLeft: "8px" },
          }}
          sx={{
            mb: 2,
            '& input:-webkit-autofill': {
              WebkitBoxShadow: '0 0 0 1000px #f7f9fd inset',
              WebkitTextFillColor: 'inherit',
            },
            '& input': {
              backgroundColor: 'transparent',
            },
          }}
        />
        <TextField
          label="Last Name"
          name="lastName"
          value={formData.lastName}
          onChange={handleChange}
          variant="outlined"
          fullWidth
          error={!!errors.lastName}
          helperText={errors.lastName}
          sx={{
            mb: 2,
            '& input:-webkit-autofill': {
              WebkitBoxShadow: '0 0 0 1000px #f7f9fd inset',
              WebkitTextFillColor: 'inherit',
            },
            '& input': {
              backgroundColor: 'transparent',
            },
          }}
          InputProps={{
            sx: { height: "36px" },
          }}
          InputLabelProps={{
            sx: { fontSize: "12px", paddingLeft: "8px" },
          }}
        />
      </Box>

      <TextField
        label="Email ID"
        name="email"
        value={formData.email}
        onChange={handleChange}
        variant="outlined"
        fullWidth
        sx={{
          mb: 2,
          '& input:-webkit-autofill': {
            WebkitBoxShadow: '0 0 0 1000px #f7f9fd inset',
            WebkitTextFillColor: 'inherit',
          },
          '& input': {
            backgroundColor: 'transparent',
          },
        }}
        error={!!errors.email}
        helperText={errors.email}
        InputProps={{
          sx: { height: '36px' },
        }}
        InputLabelProps={{
          sx: { fontSize: '12px', paddingLeft: '8px' },
        }}
      />


      <TextField
        label="Phone Number"
        name="phone"
        value={formData.phone}
        onChange={handleChange}
        variant="outlined"
        fullWidth
        error={!!errors.phone}
        helperText={errors.phone}
        InputProps={{
          sx: { height: "36px" },
        }}
        InputLabelProps={{
          sx: { fontSize: "12px", paddingLeft: "8px" },
        }}
        sx={{
          mb: 2,
          '& input:-webkit-autofill': {
            WebkitBoxShadow: '0 0 0 1000px #f7f9fd inset',
            WebkitTextFillColor: 'inherit',
          },
          '& input': {
            backgroundColor: 'transparent',
          },
        }}
      />

      <Button
        variant="contained"
        color="primary"
        fullWidth
        onClick={handleNext}
        disabled={!isFormValid()} // Disable button if form is not valid
        sx={{
          padding: "4px",
          backgroundColor: "#3644BE",
          fontSize: "14px",
          minHeight: "36px",
          "&:hover": {
            backgroundColor: "#1565c0",
          },
        }}
        endIcon={<ArrowForwardIcon />}
      >
        Next
      </Button>
    </Box>
  );
};

export default BasicDetailsForm;