// src/utils/firebase.js
import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth";

// const firebaseConfig = {
//     apiKey: "AlzaSyBust-hKeluXEiovZrp5S3-ALLweb0FrZk",
//     authDomain: "nayaati-93af6.firebaseapp.com",
//     projectId: "nayaati-93af6",
//     storageBucket: "nayaati-93af6.appspot.com",
//     messagingSenderId: "861037028412",
//     appId: "YOUR_APP_ID", 
//   };

const firebaseConfig = {
  apiKey: "AIzaSyBy5WvZ92obto7jp1bH2uv_a6KFgO-iGoA",
  authDomain: "nayaati-93af6.firebaseapp.com",
  projectId: "nayaati-93af6",
  storageBucket: "nayaati-93af6.firebasestorage.app",
  messagingSenderId: "861037028412",
  appId: "1:861037028412:web:960e32c586203a59db0f39"
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const googleProvider = new GoogleAuthProvider();
