import axios from "axios";

const API_BASE_URL = process.env.REACT_APP_API_URL || "https://vendor-api-dev.nayaati.com";

export const registerVendor = async (payload, userType) => {
  try {
    const response = await axios.post(
      `${API_BASE_URL}/api/User/RegisterVendor`,
      payload,
      {
        params: { userType },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error in registerVendor API:", error);
    throw error;
  }
};
