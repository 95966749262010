import React, { useState } from "react";
import {
  Box,
  Button,
  TextField,
  Typography,
  Checkbox,
  FormControlLabel,
  Link,
  IconButton,
  InputAdornment,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const AccountDetailsForm = ({ handleNext, handleBack, handleRegister, onDataChange }) => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    username: "",
    password: "",
    confirmPassword: "",
    acceptTerms: false,
    giveConsent: false,
  });

  const [errors, setErrors] = useState({
    username: "",
    password: "",
    confirmPassword: "",
    acceptTerms: "",
  });

  const [showPassword, setShowPassword] = useState(false); // State to toggle password visibility
  const [showConfirmPassword, setShowConfirmPassword] = useState(false); // State to toggle confirm password visibility

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    const updatedValue = type === "checkbox" ? checked : value;

    setFormData((prev) => ({
      ...prev,
      [name]: updatedValue,
    }));

    onDataChange({ [name]: updatedValue });

    validateField(name, updatedValue);
  };

  const validateField = (name, value) => {
    let error = "";

    switch (name) {
      case "username":
        if (!value.trim()) error = "Username is required.";
        break;
      case "password":
        if (!value.trim()) error = "Password is required.";
        else if (value.length < 6) error = "Password must be at least 6 characters.";
        break;
      case "confirmPassword":
        if (!value.trim()) error = "Confirm Password is required.";
        else if (value !== formData.password) error = "Passwords do not match.";
        break;
      case "acceptTerms":
        if (!value) error = "You must accept the Terms and Conditions.";
        break;
      default:
        break;
    }

    setErrors((prev) => ({
      ...prev,
      [name]: error,
    }));
  };

  const isFormValid = () => {
    return (
      formData.username &&
      formData.password &&
      formData.confirmPassword &&
      formData.password === formData.confirmPassword &&
      formData.acceptTerms &&
      Object.values(errors).every((error) => !error)
    );
  };

  const handleRegisterClick = async () => {
    if (isFormValid()) {
      try {
        await handleRegister();
        toast.success("Registration successful!");
        navigate("/"); // Redirect to the home page or login page
      } catch (error) {
        toast.error("Registration failed. Please try again.");
      }
    }
  };

  return (
    <Box>
      <Typography variant="h5" sx={{ mb: 1, fontSize: "18px", fontWeight: "bold" }}>
        Set Up Your Vendor Account
      </Typography>
      <Typography variant="body2" sx={{ mb: 3, fontSize: "14px", color: "#6B7280" }}>
        Create a secure account to manage your profile and opportunities on Nayaati.
      </Typography>

      <TextField
        label="Username"
        name="username"
        value={formData.username}
        onChange={handleChange}
        variant="outlined"
        fullWidth
        sx={{ mb: 2 }}
        error={!!errors.username}
        helperText={errors.username}
        InputProps={{
          sx: { height: "36px", padding: "0 10px", fontSize: "14px" },
        }}
        InputLabelProps={{
          sx: { fontSize: "12px", paddingLeft: "0px" },
        }}
      />

      <TextField
        label="Password"
        name="password"
        type={showPassword ? "text" : "password"}
        value={formData.password}
        onChange={handleChange}
        variant="outlined"
        fullWidth
        sx={{ mb: 2 }}
        error={!!errors.password}
        helperText={errors.password}
        InputProps={{
          sx: { height: "36px", padding: "0 10px", fontSize: "14px" },
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                onClick={() => setShowPassword((prev) => !prev)}
                edge="end"
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
        }}
        InputLabelProps={{
          sx: { fontSize: "12px", paddingLeft: "0px" },
        }}
      />

      <TextField
        label="Confirm Password"
        name="confirmPassword"
        type={showConfirmPassword ? "text" : "password"}
        value={formData.confirmPassword}
        onChange={handleChange}
        variant="outlined"
        fullWidth
        sx={{ mb: 4 }}
        error={!!errors.confirmPassword}
        helperText={errors.confirmPassword}
        InputProps={{
          sx: { height: "36px", padding: "0 10px", fontSize: "14px" },
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                onClick={() => setShowConfirmPassword((prev) => !prev)}
                edge="end"
              >
                {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
        }}
        InputLabelProps={{
          sx: { fontSize: "12px", paddingLeft: "0px" },
        }}
      />

      <Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start", gap: 2 }}>
        <FormControlLabel
          control={
            <Checkbox
              name="acceptTerms"
              checked={formData.acceptTerms}
              onChange={handleChange}
              sx={{ padding: 0 }}
            />
          }
          label={
            <Typography sx={{ fontSize: "12px", color: "#6B7280", lineHeight: "1.2" }}>
              I acknowledge and accept the Vendor{" "}
              <Link href="#" underline="hover">
                Terms and Conditions
              </Link>
              .
            </Typography>
          }
          sx={{ alignItems: "flex-start", margin: 0 }}
        />
        <FormControlLabel
          control={
            <Checkbox
              name="giveConsent"
              checked={formData.giveConsent}
              onChange={handleChange}
              sx={{ padding: 0 }}
            />
          }
          label={
            <Typography sx={{ fontSize: "12px", color: "#6B7280", lineHeight: "1.2" }}>
              I consent to Nayaati contacting me about vendor opportunities and related updates.
            </Typography>
          }
          sx={{ alignItems: "flex-start", margin: 0 }}
        />
      </Box>

      <Box sx={{ display: "flex", justifyContent: "space-between", gap: 2, mt: 3 }}>
        <Button
          variant="outlined"
          color="primary"
          sx={{
            flex: 1,
            padding: "4px",
            fontSize: "14px",
            textTransform: "none",
          }}
          onClick={handleBack}
        >
          Back
        </Button>
        <Button
          variant="contained"
          color="primary"
          sx={{
            flex: 1,
            padding: "4px",
            fontSize: "14px",
            backgroundColor: "#3644BE",
            "&:hover": {
              backgroundColor: "#1565c0",
            },
          }}
          onClick={handleRegisterClick}
          disabled={!isFormValid()} // Disable button if form is invalid
        >
          Register
        </Button>
      </Box>
    </Box>
  );
};

export default AccountDetailsForm;