// src/utils/identityManager.js

// export const users = [
//   { username: "suraj.suddala@ncompasbusiness.com", password: "password" }, 
// ];

// export const authenticateUser = (username, password) => {
//   const user = users.find(
//     (user) => user.username === username && user.password === password
//   );
//   return user ? true : false;
// };


import { signInWithPopup, signOut, signInWithEmailAndPassword } from "firebase/auth";
import { auth, googleProvider } from "./firebase";

export const signInWithGoogle = async () => {
  try {
    const result = await signInWithPopup(auth, googleProvider);
    const user = result.user;
    return user;
  } catch (error) {
    console.error("Error during Google Sign-In:", error);
    throw error;
  }
};

export const signInWithEmailPassword = async (email, password) => {
  try {
    const result = await signInWithEmailAndPassword(auth, email, password);
    return result.user;
  } catch (error) {
    console.error("Error during Email/Password Sign-In:", error);
    throw error;
  }
};

export const logout = async () => {
  try {
    await signOut(auth);
    console.log("Logged out successfully");
  } catch (error) {
    console.error("Error during logout:", error);
    throw error;
  }
};

