import React, { useState } from "react";
import BasicDetailsForm from "../components/common/BasicDetails";
import CompanyDetailsForm from "../components/common/CompanyDetailsForm";
import AccountDetailsForm from "../components/common/AccountDetailsForm";
import StepperComponent from "../components/common/StepperComponents";
import { registerVendor } from "../utils/api";
import { Box, Typography, AppBar, Toolbar, Button, Link } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

const steps = ["Basic Details", "Company Details", "Account Details"];

const Registration = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [formData, setFormData] = useState({
    basicDetails: {},
    companyDetails: {},
    accountDetails: {},
  });

  const handleNext = () => setActiveStep((prev) => prev + 1);
  const handleBack = () => setActiveStep((prev) => prev - 1);

  const handleFormDataChange = (section, data) => {
    setFormData((prev) => ({
      ...prev,
      [section]: { ...prev[section], ...data },
    }));
  };

  const handleRegister = async () => {
    try {
      // Prepare the payload in the required format
      const payload = {
        firstName: formData.basicDetails.firstName,
        lastName: formData.basicDetails.lastName,
        email: formData.basicDetails.email,
        phone: formData.basicDetails.phone,
        password: formData.accountDetails.password,
        name: formData.companyDetails.companyName,
        shippingAddress: {
          addressLine1: formData.companyDetails.address1,
          addressLine2: formData.companyDetails.address2,
          city: formData.companyDetails.city || "default_city", // Handle empty fields
          state: formData.companyDetails.state,
          country: formData.companyDetails.country || "default_country",
          lat: 0, // Replace with actual values if available
          long: 0,
          zipCode: formData.companyDetails.pinCode,
        },
        billingAddress: {
          addressLine1: formData.companyDetails.billingAddress1 || "default_address",
          addressLine2: formData.companyDetails.billingAddress2 || "default_address",
          city: formData.companyDetails.billingCity || "default_city",
          state: formData.companyDetails.billingState || "default_state",
          country: formData.companyDetails.billingCountry || "default_country",
          lat: 0,
          long: 0,
          zipCode: formData.companyDetails.billingZipCode || "default_zip",
        },
      };
      const response = await registerVendor(payload, 3); // userType is 0
      console.log("API Response:", response);
      // alert("Vendor registered successfully!");
    } catch (error) {
      console.error("API Error:", error);
      // alert("Failed to register. Please try again.");
    }
  };
  

  return (
    <Box
      sx={{
        backgroundImage: "url('/BackgroundSmall.png')",
        backgroundSize: "cover",
        minHeight: "95vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "20px",
      }}
    >
      <AppBar
        position="fixed"
        sx={{
          backgroundColor: "white",
          boxShadow: "none",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
      >
        <Toolbar sx={{ justifyContent: "space-between", padding: "0 20px", height: "60px" }}>
          <img src="/logo.png" alt="Logo" style={{ height: "40px", marginRight: "20px" }} />
          <Link component={RouterLink} to="/" sx={{ textDecoration: "none" }}>
            <Button variant="outlined" sx={{ color: "#3644BE", textTransform: "none" }}>
              Login
            </Button>
          </Link>
        </Toolbar>
      </AppBar>

      <Box sx={{ textAlign: "center", marginTop: "50px" }}>
        <Typography variant="h5">Let's get you started!</Typography>
        <StepperComponent steps={steps} activeStep={activeStep} />
      </Box>

      <Box
        sx={{
          width: "100%",
          maxWidth: "500px",
          backgroundColor: "#f8f9fb",
          borderRadius: "8px",
          padding: "30px",
        }}
      >
        {activeStep === 0 && (
          <BasicDetailsForm
            handleNext={handleNext}
            onDataChange={(data) => handleFormDataChange("basicDetails", data)}
          />
        )}
        {activeStep === 1 && (
          <CompanyDetailsForm
            handleNext={handleNext}
            handleBack={handleBack}
            onDataChange={(data) => handleFormDataChange("companyDetails", data)}
          />
        )}
        {activeStep === 2 && (
          <AccountDetailsForm
            handleNext={handleNext}
            handleBack={handleBack}
            onDataChange={(data) => handleFormDataChange("accountDetails", data)}
            handleRegister={handleRegister}
          />
        )}
      </Box>
    </Box>
  );
};

export default Registration;