import React, { useState } from "react";
import { Box, Button, TextField, Typography, MenuItem } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

const CompanyDetailsForm = ({ handleNext, handleBack, onDataChange }) => {
  const [formData, setFormData] = useState({
    companyName: "",
    companyEmail: "",
    region: "",
    address1: "",
    address2: "",
    pinCode: "",
    state: "",
  });

  const [errors, setErrors] = useState({
    companyEmail: "",
    pinCode: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;

    let error = "";
    if (name === "companyEmail") {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(value)) {
        error = "Invalid email address";
      }
    }

    if (name === "pinCode") {
      if (!/^\d{0,5}$/.test(value)) {
        error = "Pin code must be 5 digits";
      }
    }

    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));

    setErrors((prev) => ({
      ...prev,
      [name]: error,
    }));

    if (!error) {
      onDataChange({ [name]: value }); // Pass updated data to the parent component
    }
  };

  const isFormValid = () => {
    const { companyName, companyEmail, region, address1, pinCode, state } = formData;
    return (
      companyName &&
      companyEmail &&
      region &&
      address1 &&
      pinCode.length === 5 &&
      state &&
      !errors.companyEmail &&
      !errors.pinCode
    );
  };

  return (
    <Box>
      <Typography variant="h5" sx={{ mb: 1, fontSize: "18px", fontWeight: "bold" }}>
        Your Business, Your Identity
      </Typography>
      <Typography variant="body2" sx={{ mb: 3, fontSize: "14px", color: "#6B7280" }}>
        Share key details about your business to help us understand your services and expertise.
      </Typography>

      <TextField
        label="Company Name"
        name="companyName"
        value={formData.companyName}
        onChange={handleChange}
        variant="outlined"
        fullWidth
        sx={{
          mb: 2,
          "& input:-webkit-autofill": {
            WebkitBoxShadow: "0 0 0 1000px #f7f9fd inset",
            WebkitTextFillColor: "inherit",
          },
          "& input": {
            backgroundColor: "transparent",
          },
        }}
        InputProps={{
          sx: { height: "36px", padding: "0 10px", fontSize: "14px" },
        }}
        InputLabelProps={{
          sx: { fontSize: "12px", paddingLeft: "8px" },
        }}
      />

      <TextField
        label="Company Email ID"
        name="companyEmail"
        value={formData.companyEmail}
        onChange={handleChange}
        error={!!errors.companyEmail}
        helperText={errors.companyEmail}
        variant="outlined"
        fullWidth
        sx={{
          mb: 2,
          "& input:-webkit-autofill": {
            WebkitBoxShadow: "0 0 0 1000px #f7f9fd inset",
            WebkitTextFillColor: "inherit",
          },
          "& input": {
            backgroundColor: "transparent",
          },
        }}
        InputProps={{
          sx: { height: "36px", padding: "0 10px", fontSize: "14px" },
        }}
        InputLabelProps={{
          sx: { fontSize: "12px", paddingLeft: "8px" },
        }}
      />

      <TextField
        label="Region of Operation"
        name="region"
        value={formData.region}
        onChange={handleChange}
        select
        variant="outlined"
        fullWidth
        sx={{
          mb: 2,
          "& input:-webkit-autofill": {
            WebkitBoxShadow: "0 0 0 1000px #f7f9fd inset",
            WebkitTextFillColor: "inherit",
          },
          "& input": {
            backgroundColor: "transparent",
          },
        }}
        InputProps={{
          sx: { height: "36px", padding: "0 10px", fontSize: "14px", textAlign: "left" },
        }}
        InputLabelProps={{
          sx: { fontSize: "12px", paddingLeft: "8px" },
        }}
        SelectProps={{
          MenuProps: {
            PaperProps: {
              sx: {
                "& .MuiMenuItem-root": {
                  textAlign: "left",
                },
              },
            },
          },
        }}
      >
        <MenuItem value="Region 1">Region 1</MenuItem>
        <MenuItem value="Region 2">Region 2</MenuItem>
        <MenuItem value="Region 3">Region 3</MenuItem>
      </TextField>

      <TextField
        label="Address Field 1"
        name="address1"
        value={formData.address1}
        onChange={handleChange}
        variant="outlined"
        fullWidth
        sx={{
          mb: 2,
          "& input:-webkit-autofill": {
            WebkitBoxShadow: "0 0 0 1000px #f7f9fd inset",
            WebkitTextFillColor: "inherit",
          },
          "& input": {
            backgroundColor: "transparent",
          },
        }}
        InputProps={{
          sx: { height: "36px", padding: "0 10px", fontSize: "14px" },
        }}
        InputLabelProps={{
          sx: { fontSize: "12px", paddingLeft: "8px" },
        }}
      />

      <TextField
        label="Address Field 2"
        name="address2"
        value={formData.address2}
        onChange={handleChange}
        variant="outlined"
        fullWidth
        sx={{
          mb: 2,
          "& input:-webkit-autofill": {
            WebkitBoxShadow: "0 0 0 1000px #f7f9fd inset",
            WebkitTextFillColor: "inherit",
          },
          "& input": {
            backgroundColor: "transparent",
          },
        }}
        InputProps={{
          sx: { height: "36px", padding: "0 10px", fontSize: "14px" },
        }}
        InputLabelProps={{
          sx: { fontSize: "12px", paddingLeft: "8px" },
        }}
      />

      <Box sx={{ display: "flex", gap: 2, flexDirection: { xs: "column", sm: "row" }, mb: 4 }}>
        <TextField
          label="PIN Code"
          name="pinCode"
          value={formData.pinCode}
          onChange={handleChange}
          error={!!errors.pinCode}
          helperText={errors.pinCode}
          variant="outlined"
          fullWidth
          sx={{
            "& input:-webkit-autofill": {
              WebkitBoxShadow: "0 0 0 1000px #f7f9fd inset",
              WebkitTextFillColor: "inherit",
            },
            "& input": {
              backgroundColor: "transparent",
            },
          }}
          InputProps={{
            sx: { height: "36px", padding: "0 10px", fontSize: "14px" },
          }}
          InputLabelProps={{
            sx: { fontSize: "12px", paddingLeft: "8px" },
          }}
        />
        <TextField
          label="State"
          name="state"
          value={formData.state}
          onChange={handleChange}
          select
          variant="outlined"
          fullWidth
          sx={{
            "& input:-webkit-autofill": {
              WebkitBoxShadow: "0 0 0 1000px #f7f9fd inset",
              WebkitTextFillColor: "inherit",
            },
            "& input": {
              backgroundColor: "transparent",
            },
          }}
          InputProps={{
            sx: { height: "36px", padding: "0 10px", fontSize: "14px", textAlign: "left" },
          }}
          InputLabelProps={{
            sx: { fontSize: "12px", paddingLeft: "8px" },
          }}
          SelectProps={{
            MenuProps: {
              PaperProps: {
                sx: {
                  "& .MuiMenuItem-root": {
                    textAlign: "left",
                  },
                },
              },
            },
          }}
        >
          <MenuItem value="State 1">State 1</MenuItem>
          <MenuItem value="State 2">State 2</MenuItem>
          <MenuItem value="State 3">State 3</MenuItem>
        </TextField>
      </Box>

      <Box sx={{ display: "flex", justifyContent: "space-between", gap: 2, mt: 3 }}>
        <Button
          variant="outlined"
          color="primary"
          sx={{
            flex: 1,
            padding: "4px",
            fontSize: "14px",
            textTransform: "none",
          }}
          onClick={handleBack}
        >
          Back
        </Button>
        <Button
          variant="contained"
          color="primary"
          sx={{
            flex: 1,
            padding: "4px",
            fontSize: "14px",
            backgroundColor: "#3644BE",
            "&:hover": {
              backgroundColor: "#1565c0",
            },
          }}
          onClick={handleNext}
          disabled={!isFormValid()} // Disable button if form is not valid
          endIcon={<ArrowForwardIcon />}
        >
          Next
        </Button>
      </Box>
    </Box>
  );
};

export default CompanyDetailsForm;